<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách hình thức CSKH'">
          <template v-slot:toolbar>
            <div class="row">
              <button
                v-bind:style="btnCreate"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
                @click="showModalAddCustomerCareForm"
                v-if="checkPermission('CUSTOMER_CARE_INSERT')"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo hình
                thức CSKH
              </button>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <b-row class="mb-5">
              <b-col md="3">
                <div class="col">
                  <div class="row">
                    <b-lable class="mr-2 d-flex align-items-center"
                      >Doanh nghiệp:</b-lable
                    >
                    <b-form-select
                      size="sm"
                      required
                      v-model="selectedCompanySearch"
                      :options="listCompanySearch"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      v-on:change="onFilter"
                      class="col-md-7"
                    ></b-form-select>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-form-input
                  v-on:input="onFilter"
                  v-model="searchName"
                  type="text"
                  placeholder="Tìm kiếm theo tên"
                  size="sm"
                ></b-form-input>
              </b-col>
              <b-col md="3">
                <div class="col">
                  <div class="row">
                    <b-lable class="mr-2 d-flex align-items-center"
                      >Hành động:</b-lable
                    >
                    <b-form-select
                      size="sm"
                      required
                      v-model="selectedCareType"
                      :options="listTypeSearch"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      v-on:change="onFilter"
                      class="col-md-7"
                    ></b-form-select>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="careForms"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>

              <template v-slot:cell(count)="row">
                <div style="text-align: center">
                  <span v-text="row.item.count" class="text-right"></span>
                </div>
              </template>
              <template v-slot:cell(createdAt)="row">
                <p class="text-center">{{ row.item.createdAt }}</p>
              </template>

              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="editItem(row.item)" v-else>
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon-eye"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkPermission('CUSTOMER_CARE_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số hình thức CSKH:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
            <b-modal
              ref="add-customer-care-form-modal"
              hide-footer
              title="Thêm mới hình thức CSKH"
            >
              <v-form ref="form" lazy-validation>
                <div class="w-50">
                  <b-form-group
                    id="input-group-1"
                    label="Doanh nghiệp:"
                    label-for="input-1"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="selectedCompany"
                      :options="listCompany"
                      required
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Chọn doanh nghiệp --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-2"
                    label="Hành dộng:"
                    label-for="input-2"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="selectedType"
                      :options="listType"
                      required
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Chọn hành động --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      type="name"
                      v-model="name"
                      required
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('CUSTOMER_CARE_INSERT')"
                  @click="createCustomerCareForm"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddCustomerCareForm"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="update-customer-care-form-modal"
              hide-footer
              :title="
                checkPermission('CUSTOMER_CARE_UPDATE')
                  ? 'Cập nhật hình thức CSKH'
                  : 'Chi tiết hình thức CSKH'
              "
            >
              <v-form ref="form" lazy-validation>
                <div class="w-50">
                  <b-form-group
                    id="input-group-1"
                    label="Doanh nghiệp:"
                    label-for="input-1"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="selectedCompany"
                      :options="listCompany"
                      required
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Chọn doanh nghiệp --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-2"
                    label="Hành dộng:"
                    label-for="input-2"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="selectedType"
                      :options="listType"
                      required
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >-- Chọn hành động --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="w-50">
                  <b-form-group
                    id="input-group-2"
                    label-for="input-2"
                    aria-describedby="input-2-live-feedback"
                  >
                    <template>
                      <span>Tên:</span>
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="input-2"
                      type="name"
                      v-model="name"
                      required
                      placeholder="Nhập tên"
                      size="sm"
                      :state="validateState('name')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.name.required"
                      id="input-2-live-feedback"
                      >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                    >
                  </b-form-group>
                </div>

                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  v-show="checkPermission('CUSTOMER_CARE_UPDATE')"
                  @click="updateCustomerCareForm"
                  >Lưu</b-button
                >
                <b-button
                  :style="
                    checkPermission('CUSTOMER_CARE_UPDATE')
                      ? 'margin-left: 10px; font-weight: 600; width: 70px'
                      : 'margin-left: 0px; font-weight: 600; width: 70px'
                  "
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdateCustomerCareForm"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
// import { VueAutosuggest } from 'vue-autosuggest';
import { IMPORT_STOCK } from '../../../utils/constants';
// import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

// jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
//   icons: {
//     time: 'far fa-clock',
//     date: 'far fa-calendar',
//     up: 'fas fa-arrow-up',
//     down: 'fas fa-arrow-down',
//     previous: 'fas fa-chevron-left',
//     next: 'fas fa-chevron-right',
//     today: 'fas fa-calendar-check',
//     clear: 'far fa-trash-alt',
//     close: 'far fa-times-circle',
//   },
// });
// import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  data() {
    return {
      onLoading: false,
      disabledDates: {},
      btnCreate: {
        fontWeight: '600!important',
      },
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      type: IMPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'companyName',
          label: 'Doanh nghiệp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'customerCareType',
          label: 'Hành động',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        { key: 'actions', label: '' },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      limit: 10,
      searchProduct: '',
      selectedType: null,
      listType: [
        {
          id: 1,
          name: 'Tặng điểm',
        },
        {
          id: 2,
          name: 'Trừ điểm',
        },
      ],
      listCompany: [],
      selectedCompany: null,
      name: '',
      careForms: [],
      isNew: false,
      careFormId: null,
      selectedCompanySearch: null,
      selectedCareType: null,
      searchName: '',
      listTypeSearch: [
        {
          id: null,
          name: 'Tất cả',
        },
        {
          id: 1,
          name: 'Tặng điểm',
        },
        {
          id: 2,
          name: 'Trừ điểm',
        },
      ],
      listCompanySearch: [],
    };
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    onEndChange: function () {
      this.onFilter();
    },
    onStartChange: function () {
      this.onFilter();
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompanySearch = response.data.data;
        this.listCompanySearch.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      let params = {
        page: this.page,
        limit: 10,
        name: this.searchName,
        companyId: this.selectedCompanySearch,
        careType: this.selectedCareType,
      };

      ApiService.setHeader();
      ApiService.query('customerCareForm', { params })
        .then((response) => {
          this.careForms = [];
          const dataResponse = response.data.data.data;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          let result = [];
          dataResponse.forEach((item, index) => {
            let stock = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              name: item.name,
              customerCareType: item.customerCareType,
              companyId: item.companyId,
              companyName: item.companyName,
              createdAt: moment(String(item.createdAt)).format(
                'DD/MM/YYYY HH:mm:ss'
              ),
            };
            result.push(stock);
            this.careForms = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.careFormId = item.id;
      this.getCareFormById();
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa hình thức CSKH!',
        text: 'Bạn có chắc muốn xóa hình thức CSKH này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.delete(`customerCareForm/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-customer-care-form',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    showModalAddCustomerCareForm() {
      this.selectedCompany = null;
      this.selectedType = null;
      this.name = '';
      this.$refs['add-customer-care-form-modal'].show();
    },
    hideModalAddCustomerCareForm() {
      this.$refs['add-customer-care-form-modal'].hide();
    },
    createCustomerCareForm: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let data = {
        name: this.name,
        careType: this.selectedType,
        companyId: this.selectedCompany,
      };
      ApiService.setHeader();
      ApiService.post('customerCareForm', data)
        .then(({ data }) => {
          this.isNew = true;
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalAddCustomerCareForm();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.isNew = true;
          this.makeToastFaile(response.$error);
        });
    },
    showModalUpdateCustomerCareForm() {
      this.$refs['update-customer-care-form-modal'].show();
    },
    hideModalUpdateCustomerCareForm() {
      this.$refs['update-customer-care-form-modal'].hide();
    },
    getCareFormById: function () {
      ApiService.setHeader();
      ApiService.get(`customerCareForm/${this.careFormId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.formName;
            this.selectedType = data.data.customerCareType;
            this.selectedCompany = data.data.companyId;
            this.showModalUpdateCustomerCareForm();
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    updateCustomerCareForm: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      let data = {
        id: this.careFormId,
        name: this.name,
        careType: this.selectedType,
        companyId: this.selectedCompany,
      };
      ApiService.setHeader();
      ApiService.put('customerCareForm', data)
        .then(({ data }) => {
          this.isNew = true;
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.hideModalUpdateCustomerCareForm();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.isNew = true;
          this.makeToastFaile(response.$error);
        });
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('CUSTOMER_CARE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('CUSTOMER_CARE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách hình thức CSKH' },
    ]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.fetchData();
    this.fetchCompany();
  },
  components: {
    KTCodePreview,
    // VueAutosuggest,
    VclTable,
    // datePicker,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.productCode:hover {
  text-decoration: underline;
}
</style>
